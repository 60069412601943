@keyframes shudder {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    75% { transform: translateX(5px); }
}
@keyframes enlarge {
    0% { transform: scale(1); }
    100% { transform: scale(1.5); }
}


@keyframes explode {
    0% { opacity: 1; }
    100% { opacity: 0; transform: scale(2); }
}

@keyframes flyaway {
    0% { transform: translate(0, 0); }
    100% { transform: translate(-200px, -200px); }
}

.box {
    font-size: 5rem;
    display: inline-block;
    animation-duration: 0.5s;
    animation-timing-function: ease;
}

.enlarge {
    animation-name: enlarge;
}

.shudder {
    animation-name: shudder;
    animation-duration: 0.1s;
    animation-iteration-count: infinite;
}

.explode {
    animation-name: explode;
    animation-duration: 0.5s;
}

.flyawaybox {
    position: absolute;
    font-size: 2rem;
    animation-name: flyaway;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.flyaway1 {
    animation-delay: 0.5s;
}

.flyaway2 {
    animation-delay: 0.7s;
}

.invisible {
    opacity: 0;
}

.translucent {
    opacity: 0.5;
}

.opaque {
    opacity: 1;
}